<template>
  <section class="dtc-main-section mt-2 mr-2">
    <h5 class="big-title">公費生報到作業</h5>
    <header class="dtc-grid-header my-dark">
      <div v-for="(item, i) in headers" :key="`headers${i}`" :title="item">
        {{ item }}
      </div>
    </header>
    <main
      v-if="!items.length"
      class="mt-2"
      style="grid-column: 1/-1;font-size:26px;text-align:center;font-weight:600"
    >
      暫無資料
    </main>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#EDEDED ;' : 'background-color: #DDDDDD;'
      "
    >
      <div style="cursor: pointer;color:#B10021;padding-top:2px">
        <b-button
          variant="primary"
          @click="showCheckInModal(item, 1)"
          size="sm"
          v-if="item.ServiceItem === '未報到'"
          >報到</b-button
        >
        <b-button
          variant="danger"
          size="sm"
          v-else
          @click="showCheckInModal(item, 2)"
          >取消報到</b-button
        >
      </div>
      <div :title="item.StartYear">
        {{ item.StartYear || "暫無資料" }}
      </div>
      <div :title="item.ServiceItem">
        {{ item.ServiceItem || "暫無資料" }}
      </div>
      <div :title="item.Name">
        {{ item.Name || "暫無資料" }}
      </div>
      <div :title="item.Identifier">
        {{ item.Identifier || "暫無資料" }}
      </div>
      <div :title="item.Cht_BirthDate">
        {{ item.Cht_BirthDate || "暫無資料" }}
      </div>
      <div :title="item.Gender">
        {{ item.Gender == "F" ? "女" : item.Gender == "M" ? "男" : "暫無資料" }}
      </div>
      <div :title="item.SchoolName">
        {{ item.SchoolName || "暫無資料" }}
      </div>
      <div :title="item.Country">
        {{ item.Country || "暫無資料" }}
      </div>
      <div :title="item.country">
        {{ item.country || "暫無資料" }}
      </div>
      <div :title="item.ContAddress">
        {{ item.ContAddress || "暫無資料" }}
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <b-modal
      id="modalCheckIn"
      :title="`${+key == 2 ? '取消' : ''}報到作業`"
      ok-only
    >
      <p class="bigword">是否確認{{ +key == 2 ? "取消" : "" }}報到以下學生</p>
      <main class="dtc-check-grid">
        <div>姓名:</div>
        <div style="font-weight:600">{{ personData.Name || "暫無資料" }}</div>
        <div>身分證字號:</div>
        <div style="font-weight:600">
          {{ personData.Identifier || "暫無資料" }}
        </div>
        <div>出生年月日:</div>
        <div style="font-weight:600">
          {{ personData.Cht_BirthDate || "暫無資料" }}
        </div>
      </main>
      <template v-slot:modal-footer>
        <div class="w-100 text-center">
          <b-button
            :variant="+key == 2 ? 'danger' : 'primary'"
            class="ml-3 mr-1"
            @click="checkInPerson(personData.Identifier, key)"
            >{{ +key == 2 ? "取消" : "確認" }}報到
          </b-button>

          <b-button
            variant="info"
            class="ml-2"
            @click="$bvModal.hide('modalCheckIn')"
            >關閉</b-button
          >
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import moment from "moment";

const headers = [
  "報到",
  "入學年度",
  "狀態",
  "姓名",
  "身分證字號",
  "出生日期",
  "性別",
  "學校名稱",
  "籍別",
  "籍貫",
  "通訊地址",
];
const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1911;
let years = new Array(8).fill().map((s, i) => year + 1 - i);
years.unshift({ value: null, text: "全部" });

export default {
  name: "downarea",
  data() {
    return {
      headers,
      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      year: null,
      search: true,
      rows,
      totalCountStr: "",
      years,
      filename: "",
      fakeData: [],
      personData: {},
      key: 0,
      countryObject: [],
    };
  },
  components: {},
  computed: {
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const schoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
      return schoolArr.includes(loginHealthCode);
    },
  },

  methods: {
    async checkInPerson(id, key) {
      const url = `StudentInfo/ChangeRegister?identifier=${id}`;
      try {
        await window.axios.put(url);
        this.$bvToast.toast(`${+key == 2 ? "取消" : "確認"}報到成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
      } catch (error) {}
      this.$bvModal.hide("modalCheckIn");
    },
    showCheckInModal(item, index) {
      this.personData = { ...item };
      this.key = index;
      this.$bvModal.show("modalCheckIn");
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async clearSearch() {
      this.getData();
    },
    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = [""];

      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj); // + no + filter + filter2;

      if (this.healthCodeCheck) {
        this.searchSchool = sessionStorage.getItem("darcok").replace(/\"/g, "");
        qs = this.$equalFilter("SchoolCode", qs, this.searchSchool);
      }

      let { Items, Count } = await window.axios.get(
        "StudentInfo/GetNotRegister" + qs
      );
      Items = Items.map((s) => {
        s.country = this.countryObject.find((k) => k.value == s.County)
          ? this.countryObject.find((k) => k.value == s.County).text
          : "";
        return s;
      });

      this.items = Items;
      this.rowCount = Count;
      this.totalCountStr = `共${Count} 筆`;
    },

    async getCountry() {
      const arr = await window.axios.get("SysCode/Get?category=country");
      this.countryObject = arr.map((s) => ({
        value: s.Code,
        text: s.Display,
      }));
    },
  },
  async mounted() {
    this.getCountry();
    this.getData();
    this.$root.$on("get-Newest-Notregister", () => this.getData());
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  overflow-x: auto;
  padding: 12px;
  position: relative;
  height: 98vh;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns:
    repeat(1, 100px) repeat(6, 150px) 350px repeat(2, 150px)
    430px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    // word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    word-break: keep-all;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    height: 36px;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
  > div:last-child {
    // border-right: none;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #424242;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: repeat(34, max-content);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 120px;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: white;
}
#modalCheckIn {
  .bigword {
    font-weight: 900;
  }
  .dtc-check-grid {
    display: grid;
    grid-template-columns: 100px 400px;
    grid-column-gap: 5px;
    grid-row-gap: 10px;
    font-weight: 500;
    margin-left: 50px;
  }
}
.big-title {
  background: #0379fd;
  padding: 5px;
  color: #fff;
  border-radius: 6px 6px 0 0;
  border: 1px solid #bdbdbd;
}
</style>
